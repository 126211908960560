import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionWhatsapp,
  ContactInteractionWhatsappTemplate,
  WhatsappTemplate,
  WhatsappTemplateButtonsComponentUnion,
  WhatsappTemplateComponentBody,
  WhatsappTemplateComponentFooter,
  WhatsappTemplateComponentHeader,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import { Center } from 'src/components/layout/center';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  WhatsappTemplateParameterInput,
  WhatsappTemplateData,
  getWhatsappTemplateParamName,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { WhatsappTemplateParameter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/whatsapp-template-parameter';
import { PlayIcon } from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import {
  BubbleDivider,
  BubbleMessageHeader,
  MessageBubbleWithDirection,
  FailedMessageIndicator,
  Content,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import {
  ReactionsWrapper,
  getMessageComponentReaction,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reactions-wrapper';
import {
  resolveWhatsappComponentTextValue,
  sanitizeWhatsappMessage,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-subitems/shared';

const TemplateHeaderText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  display: block;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  margin-bottom: 10px;
  text-align: left;

  ${up('md')} {
    line-height: 17px;
    font-size: 14px;
  }
`;

const TemplateBodyText = styled(TemplateHeaderText)<{ marginBottom: number }>`
  font-family: ${({ theme }) => theme.font.regular};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const TemplateFooterText = styled(TemplateHeaderText)<{ $marginBottom: number }>`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

const StyledBubbleMessageHeader = styled(BubbleMessageHeader)`
  .interaction-icon {
    margin-right: 6px;
    svg {
      height: 14px;
      width: 14px;
      path {
        fill: ${({ theme }) => theme.colors.gogo};
      }
    }
  }
`;

const EditWhatsappParameterContextMenuArrowStyleOverride = createGlobalStyle<{
  theme: DefaultTheme;
}>`
.ant-dropdown.edit-whatsapp-parameter-dropdown {
    .ant-dropdown-arrow {
      width: 8px;
      border: 1px solid ${({ theme }) => theme.colors.koala};
      background: white;
      border-bottom: none;
      border-right: none;
    }
  }
`;

const TemplateComponentPreviewContainer = styled.span`
  white-space: pre-wrap;
`;

const TemplatePreviewBodyText = styled(TemplateBodyText)`
  line-height: 21px;
  font-size: 13px;

  ${up('md')} {
    font-size: 14px;
  }
`;

const StyledWhatsappTemplateButton = styled(LggButton)`
  margin-bottom: 5px;
  height: 30px;
  padding: 7px;

  &[disabled] {
    cursor: not-allowed;
  }

  &,
  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.75);
  }
`;

const WhatsAppTemplateButtonText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 18px;
`;

const WhatsAppTemplateButtonIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const WhatsappHeaderImage = styled.img`
  border-radius: 6px;
  cursor: not-allowed;
  height: 165px;
  margin-bottom: 8px;
  object-fit: cover;
  width: 100%;
`;

const StyledWhatsappVideo = styled.video`
  width: 100%;
`;

const VideoContainer = styled.div`
  height: 165px;
  overflow: hidden;
`;

const StyledCenter = styled(Center)`
  border-radius: 6px;
  cursor: not-allowed;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
`;

const WhatsappHeaderVideoOverlay = styled.div`
  background: linear-gradient(180deg, rgba(48, 68, 87, 0) 0%, #304457 100%);
  height: 100%;
  position: absolute;
  width: 100%;
`;

const WhatsappHeaderDocumentPreviewIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.koala};
  border-radius: 50%;
  height: 36px;
  margin-right: 10px;
  padding: 9px;
  width: 36px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: transparent;
    }
  }
`;

const WhatsappHeaderDocumentPreviewContainer = styled(FlexRow)`
  align-items: center;
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 10px 15px;
`;

const WhatsappHeaderDocumentPreviewDescription = styled(FlexColumn)`
  .title {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 2px;
  }

  .sub-title {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.bold};
    font-size: 8px;
    letter-spacing: 0px;
    line-height: 10px;
  }
`;

type WhatsappHeaderMediaProps = {
  src?: string;
};

const WhatsappHeaderVideoPreview = ({ src }: WhatsappHeaderMediaProps) => {
  return (
    <StyledCenter>
      <PlayIcon type="play" />
      <VideoContainer>
        <StyledWhatsappVideo>
          <source src={src} data-lgg-id="whatsapp-template-header-video-preview" />
        </StyledWhatsappVideo>
      </VideoContainer>
      <WhatsappHeaderVideoOverlay />
    </StyledCenter>
  );
};

const WhatsappHeaderImagePreview = ({ src }: WhatsappHeaderMediaProps) => (
  <WhatsappHeaderImage src={src} data-lgg-id="whatsapp-template-header-image-preview" />
);

const WhatsappHeaderDocumentPreview = () => {
  const { t } = useTranslation(['conversations']);

  return (
    <WhatsappHeaderDocumentPreviewContainer data-lgg-id="whatsapp-template-header-document-preview">
      <WhatsappHeaderDocumentPreviewIcon type="filePdf" />
      <WhatsappHeaderDocumentPreviewDescription>
        <span className="title">
          {t(
            'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.componentsPlaceholders.documentHeader.attachedFile',
          )}
        </span>
        <span className="sub-title">PDF</span>
      </WhatsappHeaderDocumentPreviewDescription>
    </WhatsappHeaderDocumentPreviewContainer>
  );
};

const WhatsappTemplateButtonItem = ({
  icon,
  label,
  testId,
}: {
  icon?: string;
  label: string;
  testId: string;
}) => {
  return (
    <StyledWhatsappTemplateButton variant="tertiary" disabled data-lgg-id={testId}>
      {icon ? (
        <WhatsAppTemplateButtonIcon
          lggTestId="whatsapp-template-button-icon"
          type={icon}
        />
      ) : null}
      <WhatsAppTemplateButtonText data-lgg-id="whatsapp-template-button-label">
        {label}
      </WhatsAppTemplateButtonText>
    </StyledWhatsappTemplateButton>
  );
};

const getButtonConfiguration = (button: WhatsappTemplateButtonsComponentUnion) => {
  try {
    return match(button)
      .with(
        {
          __typename: 'WhatsappTemplateButtonPhoneNumber',
        },
        ({ text }) => ({ icon: 'phone', label: text, type: 'phone-number' }),
      )
      .with(
        {
          __typename: 'WhatsappTemplateButtonQuickReply',
        },
        ({ text }) => ({ icon: 'reply', label: text, type: 'quick-reply' }),
      )
      .with(
        {
          __typename: 'WhatsappTemplateButtonUrl',
        },
        ({ text }) => ({ icon: 'externalLink', label: text, type: 'url' }),
      )
      .with(
        {
          __typename: 'WhatsappTemplateButtonCatalog',
        },
        ({ text }) => ({ icon: undefined, label: text, type: 'catalog' }),
      )
      .with(
        {
          __typename: 'WhatsappTemplateButtonFlow',
        },
        ({ text }) => ({ icon: 'draft', label: text, type: 'flow' }),
      )
      .exhaustive();
  } catch {
    return { icon: undefined, label: '', type: 'default-button' };
  }
};

const CatalogPreviewContainer = styled(FlexRow)`
  align-items: center;
  background: rgba(87, 114, 138, 0.08);
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 8px;
`;

const CatalogPreviewImage = styled(Icon)`
  background: ${({ theme }) => theme.colors.koala};
  border-radius: 4px;
  height: 48px;
  padding: 15px;
  width: 48px;
  margin-right: 10px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const CatalogPreviewTitle = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.smalt};
  margin-bottom: 3px;
`;

const CatalogPreviewSubTitle = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.storm};
`;

const WhatsappTemplateCatalogButtonPreview = () => {
  const { t } = useTranslation(['conversations']);

  return (
    <CatalogPreviewContainer data-lgg-id="whatsapp-template-catalog-preview">
      <CatalogPreviewImage type="mediatypeImage" />
      <FlexColumn>
        <CatalogPreviewTitle>
          {t(
            'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.componentsPlaceholders.catalogButton.title',
          )}
        </CatalogPreviewTitle>
        <CatalogPreviewSubTitle>
          {t(
            'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.componentsPlaceholders.catalogButton.subTitle',
          )}
        </CatalogPreviewSubTitle>
      </FlexColumn>
    </CatalogPreviewContainer>
  );
};

const WhatsappTemplateButtons = ({
  buttons,
  topContentRef,
}: {
  buttons: WhatsappTemplateButtonsComponentUnion[];
  topContentRef: HTMLSpanElement | null;
}) => {
  const { t } = useTranslation(['conversations']);

  const buttonsConfigList: { icon?: string; label: string; type: string }[] =
    useMemo(() => {
      const showViewMoreButton = buttons.length > 3;

      if (showViewMoreButton) {
        return [
          ...buttons.slice(0, 2).map(getButtonConfiguration),
          {
            icon: 'moreOptions',
            label: t(
              'conversations:contactInteractionBubble.whatsapp.templateButtons.seeAllOptions',
            ),
            type: 'more-options',
          },
        ];
      }

      return buttons.map(getButtonConfiguration);
    }, [buttons, t]);

  const catalogButton = buttons.find((value) => value.type === 'CATALOG');

  return (
    <>
      {topContentRef && catalogButton
        ? createPortal(<WhatsappTemplateCatalogButtonPreview />, topContentRef)
        : null}
      {buttonsConfigList.map(({ icon, label, type }, index) => {
        const testId = `whatsapp-template-button-${type}-${index}`;

        return (
          <WhatsappTemplateButtonItem
            key={testId}
            icon={icon}
            label={label}
            testId={testId}
          />
        );
      })}
    </>
  );
};

const whatsappTemplateParamRegex = /{{(?:\d+|\w+)}}/g;

const matchWhatsappParamByKey = (
  param: WhatsappTemplateParameterInput,
  targetKey: string,
) => param.key === targetKey;

type WhatsappTemplatePreviewItemProps = {
  occurredAt: string;
  template: WhatsappTemplate;
  parameters: WhatsappTemplateData;
  setParameters: ValueChanged<WhatsappTemplateData>;
};

export const WhatsappTemplatePreviewItem = memo<WhatsappTemplatePreviewItemProps>(
  ({ template, occurredAt, parameters, setParameters }) => {
    const hasFooterComponent = useMemo(
      () =>
        template.components.some(
          (component) => component.__typename === 'WhatsappTemplateComponentFooter',
        ),
      [template.components],
    );
    const buttonComponent = useMemo(
      () =>
        template.components.find(
          (component) => component.__typename === 'WhatsappTemplateComponentButtons',
        ),
      [template.components],
    );
    const hasButtonsComponent = useMemo(
      () => buttonComponent !== undefined,
      [buttonComponent],
    );

    const buttonPayload = useMemo(
      () =>
        match(buttonComponent)
          .with({ buttons: [{ type: 'CATALOG' }] }, () => ({
            type: 'BUTTON',
            subType: 'CATALOG',
          }))
          .with({ buttons: [{ type: 'FLOW' }] }, () => ({
            type: 'BUTTON',
            subType: 'FLOW',
          }))
          .otherwise(() => null),
      [buttonComponent],
    );

    const getTemplateParameterInputDefaultState = useCallback(
      (key: string) => ({
        key,
        hasError: false,
        value: undefined,
      }),
      [],
    );
    const topContentRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (!parameters.rawBody) {
        const rawBody =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentBody',
          ) as WhatsappTemplateComponentBody) || undefined;
        const bodyParams = [
          ...(rawBody?.text.match(whatsappTemplateParamRegex) || [])?.map((paramKey) =>
            getTemplateParameterInputDefaultState(paramKey),
          ),
        ];

        const rawHeader =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentHeader',
          ) as WhatsappTemplateComponentHeader) || undefined;

        const headerParams = [
          ...(rawHeader?.text.match(whatsappTemplateParamRegex) || [])?.map((paramKey) =>
            getTemplateParameterInputDefaultState(paramKey),
          ),
        ];

        const rawFooter =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentFooter',
          ) as WhatsappTemplateComponentFooter) || undefined;

        setParameters({
          ...parameters,
          rawBody: rawBody.text,
          rawHeader: rawHeader?.text,
          rawFooter: rawFooter?.text,
          body: bodyParams,
          header: headerParams,
          bodyParamsCount: bodyParams.length,
          headerParamsCount: headerParams.length,
        });
      }
    }, [
      parameters,
      setParameters,
      template.components,
      getTemplateParameterInputDefaultState,
    ]);

    useEffect(() => {
      if (buttonPayload && !parameters.button) {
        setParameters({
          ...parameters,
          button: buttonPayload,
        });
      }
    }, [buttonPayload, parameters, setParameters]);

    const resolveComponentValue = useCallback(
      (component: WhatsappTemplateComponentHeader | WhatsappTemplateComponentBody) => {
        const resolvedText = component.text;

        const getComponentKey = (key: string) => {
          // All cases handled for fixed values
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          return match(component)
            .with(
              { __typename: 'WhatsappTemplateComponentHeader' },
              () => `header-param-${key}`,
            )
            .with(
              { __typename: 'WhatsappTemplateComponentBody' },
              () => `body-param-${key}`,
            )
            .exhaustive();
        };

        const textComponents = resolvedText
          .split(whatsappTemplateParamRegex)
          .map((textValue) => (
            <span key={getComponentKey(`text-${textValue}`)}>{textValue}</span>
          ));

        const getParamValue = (key: string) => {
          // All cases handled for fixed values
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          return match(component)
            .with({ __typename: 'WhatsappTemplateComponentBody' }, () =>
              parameters.body.find((param) => matchWhatsappParamByKey(param, key)),
            )
            .with({ __typename: 'WhatsappTemplateComponentHeader' }, () =>
              parameters.header.find((param) => matchWhatsappParamByKey(param, key)),
            )
            .exhaustive();
        };

        const setParamValue = (paramKey: string, value: string | undefined) => {
          const getUpdatedParamValue = (
            value: string,
          ): WhatsappTemplateParameterInput => ({
            key: paramKey,
            hasError: false,
            value: {
              type: 'text',
              text: value,
            },
          });

          // All cases handled for fixed values
          // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
          return match(component)
            .with({ __typename: 'WhatsappTemplateComponentHeader' }, () => {
              const updatedParams = [...parameters.header];
              const paramIndex = updatedParams.findIndex((param) =>
                matchWhatsappParamByKey(param, paramKey),
              );

              if (!value) {
                updatedParams[paramIndex] =
                  getTemplateParameterInputDefaultState(paramKey);
              } else {
                updatedParams[paramIndex] = getUpdatedParamValue(value);
              }

              setParameters({
                ...parameters,
                header: updatedParams,
              });
            })
            .with({ __typename: 'WhatsappTemplateComponentBody' }, () => {
              const updatedParams = [...parameters.body];
              const paramIndex = updatedParams.findIndex((param) =>
                matchWhatsappParamByKey(param, paramKey),
              );

              if (!value) {
                updatedParams[paramIndex] =
                  getTemplateParameterInputDefaultState(paramKey);
              } else {
                updatedParams[paramIndex] = getUpdatedParamValue(value);
              }

              setParameters({
                ...parameters,
                body: updatedParams,
              });
            })
            .exhaustive();
        };

        const paramsComponents = [
          ...resolvedText.matchAll(whatsappTemplateParamRegex),
        ].map(([paramKey]) => {
          const paramValue = getParamValue(paramKey);
          const componentKey = getComponentKey(getWhatsappTemplateParamName(paramKey));

          return (
            <WhatsappTemplateParameter
              testId={componentKey}
              key={componentKey}
              label={paramKey}
              value={paramValue?.value?.text}
              hasError={paramValue?.hasError}
              onChange={(value) => {
                setParamValue(paramKey, value);
              }}
            />
          );
        });

        const templateComponents: JSX.Element[] = [];

        textComponents.forEach((component, index) => {
          templateComponents.push(component);
          templateComponents.push(paramsComponents[index]);
        });

        return (
          <TemplateComponentPreviewContainer>
            {templateComponents.map((component) => component)}
          </TemplateComponentPreviewContainer>
        );
      },
      [getTemplateParameterInputDefaultState, parameters, setParameters],
    );

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-whatsapp-template"
        registrationType="AUTOMATIC"
        direction="OUTBOUND"
        deliveryStatus="DELIVERED"
        details={null}
        createdAt={occurredAt}
      >
        <>
          <span
            data-lgg-id="message-bubble-preview-top-content"
            ref={topContentRef}
          ></span>
          {template.components.map((component) => {
            // Keep in mind that WhatsappTemplateComponentUnion may change
            // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
            return match(component)
              .with({ __typename: 'WhatsappTemplateComponentHeader' }, (component) => (
                <TemplateHeaderText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-header"
                >
                  {resolveComponentValue(component)}
                </TemplateHeaderText>
              ))
              .with(
                { __typename: 'WhatsappTemplateComponentMediaHeader' },
                (component) => {
                  const mediaUrl = component.example.headerHandle?.[0] ?? '';

                  return match(component.format)
                    .with('IMAGE', () => <WhatsappHeaderImagePreview src={mediaUrl} />)
                    .with('VIDEO', () => <WhatsappHeaderVideoPreview src={mediaUrl} />)
                    .with('DOCUMENT', () => <WhatsappHeaderDocumentPreview />)
                    .otherwise(() => null);
                },
              )
              .with({ __typename: 'WhatsappTemplateComponentBody' }, (component) => (
                <TemplatePreviewBodyText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-body"
                  marginBottom={hasFooterComponent ? 8 : 15}
                >
                  {resolveComponentValue(component)}
                </TemplatePreviewBodyText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentFooter' }, (component) => (
                <TemplateFooterText
                  $marginBottom={hasButtonsComponent ? 5 : 13}
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                >
                  <TextFormatter>{component.text}</TextFormatter>
                </TemplateFooterText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentButtons' }, (component) => (
                <WhatsappTemplateButtons
                  key={component.__typename}
                  buttons={component.buttons}
                  topContentRef={topContentRef.current}
                />
              ))
              .exhaustive();
          })}
          <EditWhatsappParameterContextMenuArrowStyleOverride />
        </>
      </MessageBubbleWithDirection>
    );
  },
);

export const WhatsappTemplateItem = (props: {
  contactInteraction: ContactInteractionWhatsapp & {
    template: ContactInteractionWhatsappTemplate;
  };
  conversationId: string;
}) => {
  const { t } = useTranslation(['conversations']);
  const { contactInteraction, conversationId } = props;
  const {
    registrationType,
    id,
    description,
    occurredAt,
    source,
    campaign,
    department,
    template,
    isMasked,
  } = contactInteraction;

  const hasFooterComponent = template.components.some(
    (component) =>
      component.__typename === 'ContactInteractionWhatsappTemplateComponentFooter',
  );

  const hasButtonsComponent = template.components.some(
    (component) =>
      component.__typename === 'ContactInteractionWhatsappTemplateComponentButtons',
  );

  const messageReaction = getMessageComponentReaction(contactInteraction);

  const showFailedMessageError = template.status === 'FAILED';
  const topContentRef = useRef<HTMLSpanElement | null>(null);
  const [topRef, setTopRef] = useState<HTMLSpanElement | null>(null);

  return (
    <>
      <ReactionsWrapper
        reactions={messageReaction ? [messageReaction] : messageReaction}
        direction={contactInteraction.direction}
      >
        <MessageBubbleWithDirection
          testId="contact-interaction-whatsapp-template"
          registrationType={registrationType}
          direction="OUTBOUND"
          deliveryStatus={template.status}
          details={{
            interactionId: id,
            conversationId,
            source,
            campaign,
            department,
            message: description,
          }}
          createdAt={occurredAt}
        >
          <>
            <StyledBubbleMessageHeader
              icon="whatsappTemplates"
              contactInteraction={contactInteraction}
              title={t('conversations:contactInteractionBubble.whatsapp.template')}
            />
            <BubbleDivider direction="OUTBOUND" />
            <Content isBlurred={isMasked}>
              <span
                data-lgg-id="message-bubble-top-content"
                ref={(ref) => {
                  topContentRef.current = ref;
                  setTopRef(ref);
                }}
              ></span>
              {template.components.map((component) => {
                // Keep in mind that ContactInteractionWhatsappTemplateComponentUnion may change
                // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
                return match(component)
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentHeader' },
                    (component) => (
                      <TemplateHeaderText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-header"
                      >
                        <TextFormatter>
                          {resolveWhatsappComponentTextValue(component)}
                        </TextFormatter>
                      </TemplateHeaderText>
                    ),
                  )
                  .with(
                    {
                      __typename:
                        'ContactInteractionWhatsappTemplateComponentMediaHeader',
                    },
                    (component) => {
                      const mediaUrl = component.example.headerHandle?.[0];

                      return match(component.format)
                        .with('IMAGE', () => (
                          <WhatsappHeaderImagePreview
                            src={mediaUrl}
                            key={component.__typename}
                          />
                        ))
                        .with('VIDEO', () => (
                          <WhatsappHeaderVideoPreview
                            src={mediaUrl}
                            key={component.__typename}
                          />
                        ))
                        .with('DOCUMENT', () => (
                          <WhatsappHeaderDocumentPreview key={component.__typename} />
                        ))
                        .otherwise(() => null);
                    },
                  )
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentBody' },
                    (component) => (
                      <TemplateBodyText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-body"
                        marginBottom={hasFooterComponent ? 5 : 15}
                      >
                        <TextFormatter>
                          {sanitizeWhatsappMessage(
                            resolveWhatsappComponentTextValue(component),
                          )}
                        </TextFormatter>
                      </TemplateBodyText>
                    ),
                  )
                  .with(
                    { __typename: 'ContactInteractionWhatsappTemplateComponentFooter' },
                    (component) => (
                      <TemplateFooterText
                        key={component.__typename}
                        data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                        $marginBottom={hasButtonsComponent ? 5 : 13}
                      >
                        <TextFormatter>{component.text}</TextFormatter>
                      </TemplateFooterText>
                    ),
                  )
                  .with(
                    {
                      __typename: 'ContactInteractionWhatsappTemplateComponentButtons',
                    },
                    (component) => (
                      <WhatsappTemplateButtons
                        key={component.__typename}
                        buttons={component.buttons}
                        topContentRef={topRef}
                      />
                    ),
                  )
                  .exhaustive();
              })}
            </Content>
          </>
        </MessageBubbleWithDirection>
      </ReactionsWrapper>
      {showFailedMessageError && <FailedMessageIndicator {...template} />}
    </>
  );
};
